import React , { Suspense } from "react";
import { useLoaderData, useOutlet, Await, Navigate} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { AuthProvider } from "../../hooks/useAuth";

function ReviewsError():JSX.Element {
  // const error = useAsyncError();
  // return <div>ergtewrteg</div>;
  console.log('errorrr')
  return <Navigate to={'/login'} replace/>;
}
const AuthLayout=():JSX.Element =>{
  const outlet = useOutlet();

  const {userPromise} = useLoaderData() as {userPromise:string | null};
 
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        errorElement={<ReviewsError />}
      >{(user) => (
        <AuthProvider userData={user}>
          {outlet}
        </AuthProvider>
      )}</Await>
    </Suspense>
  );
};
export default AuthLayout