import React, {type FunctionComponent, type PropsWithChildren , useMemo} from 'react';
// import React, { useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import customShadows, { type customShadowsT } from './customShadows'

// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: customShadowsT
  }
  interface ThemeOptions {
    customShadows: customShadowsT
  }
  interface PaletteColor {
    darker?: string
  }

  interface SimplePaletteColorOptions {
    darker?: string
  }
}

const ThemeProvider :FunctionComponent<PropsWithChildren>  = ({ children }): JSX.Element => {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: palette.grey[500],
            },
          },
        },
      }
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  //   theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {/* <GlobalStyles /> */}
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
export default ThemeProvider
