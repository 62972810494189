
import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable, type MRT_ColumnDef
} from 'material-react-table';
import { Card } from '@mui/material';
import moment from 'moment';
import requestInstance from 'utils/request'
// import { SalesAPI } from 'types/dashboard';
import { type salesRecord } from 'types/salesRecord';
// @mui

// ----------------------------------------------------------------------

interface Comment {
  name: string
  phone: string
  deliveryDate: string
  rate: string
  feedbackDate: string
  description: string

}

const AppFeedbacksTable = ({ id }: { id: string }): JSX.Element => {
  //  data and fetching state
  const [data, setData] = useState<Comment[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //  table state

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    requestInstance.get(
      `sale/?business=${id}&per_page=${pagination.pageSize}&page=${pagination.pageIndex + 1}`
    ).then(res => {
      const json = res.data;
      const list = json.data.map((item: salesRecord) => ({
        name: item.customer != null ? item.customer.full_name : '-',
        phone: item.customer != null ? '+' + item.customer.country_code + item.customer.mobile : '-',
        deliveryDate: item.delivery_date != null ? moment(item.delivery_date).format('YYYY-MM-DD HH:mm') : '-',
        rate: item.rate,
        feedbackDate: item.updated_at != null ? moment(item.updated_at).format('YYYY-MM-DD HH:mm') : '-',
        description: item.description,
      }));
      setData(list);
      setRowCount(res.data.count);
    }).catch(err => {
      setIsError(true);
      console.log(err)
    }).finally(() => {
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    })
  }, [
    pagination.pageIndex,
    pagination.pageSize,
  ]);


  const columns = useMemo<Array<MRT_ColumnDef<Comment>>>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 2,
      },

      {
        accessorKey: 'phone',
        header: 'Phone',
        size: 2,
      },
      {
        accessorKey: 'deliveryDate',
        header: 'Delivery Date',
        size: 2,
      },
      {
        accessorKey: 'rate',
        header: 'rate',
        size: 1,
      },
      {
        accessorKey: 'feedbackDate',
        header: 'Feedback Date',
        size: 2,
      },
      {
        accessorKey: 'description',
        header: 'Comment',
      },
    ],
    [],
  );
  return (
    <Card sx={{ backgroundColor: 'white' }}>
      <MaterialReactTable
        enableFilters={false}
        columns={columns}
        data={data}
        enableSorting={false}
        // getRowId={(row) => row.phoneNumber}
        initialState={{ showColumnFilters: true }}
        manualPagination
        muiTablePaperProps={{}}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
        }}
      />
    </Card>
  );
};

export default AppFeedbacksTable;
