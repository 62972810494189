import React , { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';
import { type SxProps } from '@mui/material/styles'

// ----------------------------------------------------------------------

interface IconifyProps {
  icon:string,
  width:string | number,
  sx? : SxProps
}

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: IconifyProps, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

// Iconify.propTypes = {
//   sx: PropTypes.object,
//   width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
// };
Iconify.displayName = 'iconify';
export default Iconify;
