import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "./useLocalStorage";
import { type user } from "types/auth";



interface AuthContextType {
  user: user | null;
  login: (user: user) => void;
  logout: () => void;
};


export const AuthContext = createContext<AuthContextType>({
  user: null,
  login: () => { },
  logout: () => { }
});


export const AuthProvider = ({ children, userData }: { children: React.ReactNode, userData: user | null }): JSX.Element => {
  const [user, setUser] = useLocalStorage<user | null>("user", userData);
  const navigate = useNavigate();

  const login = (data: user): void => {
    setUser(data);
    // navigate("/dashboard/profile", { replace: true });
  };

  const logout = (): void => {
    // localStorage.removeItem('user')
    // setUser(null);
    localStorage.removeItem('user')
    navigate("/login", { replace: true });
  };
  const u: user | null = user
  const value = useMemo(
    () => ({
      user: u,
      login,
      logout
    }),
    [user]
  );
  // return <AuthContext></AuthContext>
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
