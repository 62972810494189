import React from 'react'
import {
  Typography,
  Card,
  CardContent,
  Stack,
  CardMedia,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { PointType, type Reward } from 'types/customer'
import { useNavigate } from 'react-router-dom'

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  height: '120px',
  cursor: 'pointer'

}))

interface CustomerProfileRewardCardProps {
  reward: Reward,
  loyaltymodel?: PointType
}

const CustomerProfileRewardCard = ({ reward, loyaltymodel }: CustomerProfileRewardCardProps): JSX.Element => {
  const navigate = useNavigate()
  return (
    <>
      <StyledCard onClick={() => { navigate(`/rewarddetail/${reward.id}`, { replace: true }) }}>
        <Stack>
          <CardMedia
            component='img'
            sx={{ objectFit: 'contain', width: '120px' }}
            src={reward.cover_photo}
            alt='Live from space album cover'
          />
        </Stack>
        <CardContent
          sx={{
            flex: '3',
            textAlign: 'left',
            marginBottom: 'auto',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            // width: '100%',
          }}
        >
          <Typography variant='h6'>{reward.name}</Typography>
          <Typography variant='subtitle2'>{reward.loyalty_weight} {loyaltymodel !== undefined ? PointType[loyaltymodel] : PointType.Points}</Typography>
          <Typography
            variant='body2'
            color='text.secondary'
            noWrap
          // sx={{ textOverflow: 'ellipsis' }}
          >
            {reward.description}
          </Typography>
        </CardContent>
      </StyledCard>
    </>
  )
}
export default CustomerProfileRewardCard
