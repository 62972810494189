import React, { useState } from "react"
import {
  SwipeableDrawer,
  Box,
  Select,
  Typography,
  Button,
  CardActions,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  useMediaQuery,
  Modal,
  Fade,
  Card,
  useTheme,
  type SxProps,



} from "@mui/material"
import TodayIcon from '@mui/icons-material/Today'

import moment from "moment"

interface DatepickerProps {
  value?: string
  onChange: React.Dispatch<React.SetStateAction<string | null>> | ((input: string) => void)
  handleOpen: () => void
  handleClose: () => void
  open: boolean
  label?: string
}

const style: SxProps = {
  borderRadius: '8px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const Datepicker = ({ onChange, handleOpen, handleClose, label, value }: DatepickerProps): JSX.Element => {
  const date = new Date()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const years = Array.from({ length: 60 }, (value, index) => date.getFullYear() - index);
  const [day, setDay] = useState<number | ''>(value !== '' && value !== undefined ? new Date(value).getDate() : '')
  const [month, setMonth] = useState<number | ''>(value !== '' && value !== undefined ? new Date(value).getMonth() + 1 : '')
  const [year, setYear] = useState<number | ''>(value !== '' && value !== undefined ? new Date(value).getFullYear() : '')
  const [open, setOpen] = useState<boolean>(false)
  const [valueLabel, setValueLabe] = useState<string | ''>(moment(value).isValid() ? moment(value).format('DD-MMM-YYYY') : '')



  return (
    <>
      <TextField
        value={valueLabel}
        fullWidth
        onClick={() => { setOpen(prev => !prev) }}
        label='Date of birth (optional)'
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <TodayIcon />
            </InputAdornment>
          ),
        }}
      />
      {matches && (
        <Modal
          open={open}
          onClose={() => { setOpen(false) }}
        >
          <Fade in={open}>
            <Card elevation={5} sx={style}>
              <Typography  variant='h6' component='h2'>
                Date of birth (optional)
              </Typography>


              <Grid
                sx={{ mt: 2 }}
                direction={'row'}
                container display={'flex'}
                justifyContent={'space-between'}>


                <Grid item xs={3} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel id="day-select">Day</InputLabel>
                    <Select
                      label="Day"
                      labelId="day-select"
                      id="demo-simple-select"
                      value={day}
                      onChange={(event) => {
                        setDay(Number(event.target.value))
                        onChange(`${year}-${month}-${event.target.value}`)
                        setValueLabe(`${event.target.value?.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        })}-${month !== '' ? monthsShort[month - 1] : 'MM'}-${year !== '' ? year : 'YYYY'}`)

                      }}

                      fullWidth>
                      {days.map((day, index) =>
                        <MenuItem key={`day-datepicker-${index}-${day.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        })}`}
                          value={day}>
                          {day}
                        </MenuItem>)}
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item xs={5} sm={5}>
                  <FormControl fullWidth>
                    <InputLabel id="month-select">Month</InputLabel>
                    <Select fullWidth
                      label='Month'
                      labelId="month-select"
                      onChange={(event) => {
                        setMonth(Number(event.target.value))
                        setValueLabe(`${day !== '' ? day.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        }) : 'DD'}-${monthsShort[Number(event.target.value) - 1]}-${year !== '' ? year : 'YYYY'}`)
                        onChange(`${year}-${Number(event.target.value)}-${day}`)

                      }}
                      value={month}
                    >
                      {Monthes.map((month, index) =>
                        <MenuItem
                          key={`day-datepicker-${index}-${index}`}
                          value={index + 1}>
                          {month}
                        </MenuItem>)}

                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={3} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id="year-select">Year</InputLabel>
                    <Select
                      labelId="year-select"
                      label="Year"
                      value={year}
                      onChange={(event) => {
                        setYear(Number(event.target.value))
                        setValueLabe(`${day !== '' ? day.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        }) : 'DD'}-${month !== '' ? monthsShort[month - 1] : 'MM'}-${event.target.value}`)
                        onChange(`${Number(event.target.value)}-${month}-${day}`)

                      }}
                      fullWidth>
                      {years.map((year, index) => <MenuItem key={`year-datepicker-${index}-${year}`} value={year}>{year}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>


              {
                <CardActions sx={{ mt: 2, width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                  <Button
                    onClick={() => { setOpen(false) }}
                    variant='contained'
                    color='secondary'
                    sx={{ width: '100%' }}
                    size='large'
                  >
                    Submit
                  </Button>
                </CardActions>
              }
            </Card>
          </Fade>
        </Modal>
      )}
      {!matches && <SwipeableDrawer
        anchor={'bottom'}
        open={open}
        onClose={() => { setOpen(false) }}
        onOpen={() => { setOpen(true) }}
        sx={{ '& .MuiPaper-root': { padding: '20px 20px 20px 20px', borderRadius: '10px 10px 0 0 ' } }}
      >
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>

          <Typography variant="h4">{label}</Typography>
        </Box>
        <Grid direction={'row'} container display={'flex'} justifyContent={'space-between'}>

          <Grid item xs={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="day-select">Day</InputLabel>
              <Select
                label="Day"
                labelId="day-select"
                id="demo-simple-select"
                value={day}
                onChange={(event) => {
                  setDay(Number(event.target.value))
                  onChange(`${year}-${month}-${event.target.value}`)
                  setValueLabe(`${event.target.value?.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}-${month !== '' ? monthsShort[month - 1] : 'MM'}-${year !== '' ? year : 'YYYY'}`)

                }}

                fullWidth>
                {days.map((day, index) =>
                  <MenuItem key={`day-datepicker-${index}-${day.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}`}
                    value={day}>
                    {day}
                  </MenuItem>)}
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={5} sm={5}>
            <FormControl fullWidth>
              <InputLabel id="month-select">Month</InputLabel>
              <Select fullWidth
                label='Month'
                labelId="month-select"
                onChange={(event) => {
                  setMonth(Number(event.target.value))
                  setValueLabe(`${day !== '' ? day.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  }) : 'DD'}-${monthsShort[Number(event.target.value) - 1]}-${year !== '' ? year : 'YYYY'}`)
                  onChange(`${year}-${Number(event.target.value)}-${day}`)

                }}
                value={month}
              >
                {Monthes.map((month, index) =>
                  <MenuItem
                    key={`day-datepicker-${index}-${index}`}
                    value={index + 1}>
                    {month}
                  </MenuItem>)}

              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={3} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="year-select">Year</InputLabel>
              <Select
                labelId="year-select"
                label="Year"
                value={year}
                onChange={(event) => {
                  setYear(Number(event.target.value))
                  setValueLabe(`${day !== '' ? day.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  }) : 'DD'}-${month !== '' ? monthsShort[month - 1] : 'MM'}-${event.target.value}`)
                  onChange(`${Number(event.target.value)}-${month}-${day}`)

                }}
                fullWidth>
                {years.map((year, index) => <MenuItem key={`year-datepicker-${index}-${year}`} value={year}>{year}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

        </Grid>
        {
          <CardActions sx={{ mt: 2, width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Button
              onClick={() => { setOpen(false) }}
              variant='contained'
              color='secondary'
              sx={{ width: '100%' }}
              size='large'
            >
              Submit
            </Button>
          </CardActions>
        }
      </SwipeableDrawer>}
    </>
  )
}
export default Datepicker

const Monthes = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jue',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]