import React, { useState } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from '@mui/material'
// mocks_
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { PointType } from 'types/customer'
// ----------------------------------------------------------------------

const AccountPopover = (): JSX.Element => {
  const [open, setOpen] = useState<HTMLButtonElement | null>(null)
  const { user, logout } = useAuth()

  const navigate = useNavigate()
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setOpen(event.currentTarget)
  }

  const handleClose = (): void => {
    setOpen(null)
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open !== null
            ? {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }
            : {}),
        }}
      >
        <Avatar
          src={
            user?.avatar !== null
              ? user?.avatar
              : '/assets/images/avatars/avatar_default.jpg'
          }
          alt='photoURL'
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user?.manager_full_name}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {/* {MENU_OPTIONS.map((option) => ( */}
          <MenuItem key='HOME' onClick={() => { navigate('/dashboard') }}>
            Home
          </MenuItem>
          {user?.loyalty_model === PointType.Stamps && <MenuItem key='QRCODE' onClick={() => { navigate('/qrcode') }}>
            QrCode
          </MenuItem>}
          {/* ))} */}

        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => { logout() }} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  )
}
export default AccountPopover