
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Card } from '@mui/material';
import moment from 'moment';
import requestInstance from 'utils/request';
import type {  RedeemListAPI, RedeemTable } from 'types/customer';
// @mui

// ----------------------------------------------------------------------
// for enable sort, filter and global filter features, uncomment those sections


const AppRedeemsTable = ({ id }: { id: string }): JSX.Element => {

  //  data and fetching state
  const [data, setData] = useState<RedeemTable[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //  table state
  // const [columnFilters, setColumnFilters] = useState([]);
  // const [globalFilter, setGlobalFilter] = useState('');
  // const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    requestInstance.get<RedeemListAPI>(
      `/redeem/?customer__business=${id}&per_page=${pagination.pageSize}&page=${pagination.pageIndex + 1}`
    ).then(res => {
      const json = res.data;
      const list = json.data.map((item) => {
        const customer: RedeemTable = {
          customer_id: item.customer.id ?? '-',
          mobile:`+${item.customer.country_code}${item.customer.mobile}`?? '-',
          gender:item.customer.gender?? '-',
          dob:item.customer.dob?? '-',
          email:item.customer.email?? '-',
          full_name:item.customer.full_name?? '-',
          customer_created_at:item.customer.created_at?? '-',
          customer_updated_at:item.customer.updated_at?? '-',
          last_sale_id:item.customer.last_sale_id?? '-',
          first_sale:item.customer.first_sale,
          customer_loyalty_weight:item.customer.points?? 0,
          acquisition_channel:item.customer.acquisition_channel,
          phone_verification:item.customer.phone_verification,
          reward_id:item.reward.id?? '-',
          useable:item.reward.useable?? '-',
          name:item.reward.name?? '-',
          cover_photo:item.reward.cover_photo?? '-',
          description:item.reward.description?? '-',
          reward_loyalty_weight:item.reward.loyalty_weight?? '-',
          start_date:item.reward.start_date?? '-',
          end_date:item.reward.end_date?? '-',
          capacity:item.reward.capacity?? '-',
          is_enabled:item.reward.is_enabled?? '-',
          reusable:item.reward.reusable?? '-',
          created_at: moment(item.created_at).format('YYYY-MM-DD HH:mm')?? '-',
          updated_at:item.updated_at?? '-',
          remaining:item.reward.remaining?? '-',
          id:item.id??'-'
        }
        return customer
      });
      setData(list);
      setRowCount(json.count);

    }).catch(err => {
      setIsError(true);
      console.error(err);
    });
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // columnFilters,
    // globalFilter,
    // sorting,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const columns = useMemo<Array<MRT_ColumnDef<RedeemTable>>>(
    () => [
      {
        accessorKey: 'id',
        header: 'Reference',
        size: 2,
      },
      {
        accessorKey: 'name',
        header: 'Reward_name',
        size: 2,
      },
      {
        accessorKey: 'full_name',
        header: 'Customer_name',
        size: 2,
      },
      {
        accessorKey: 'mobile',
        header: 'Mobile',
        size: 2,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 2,
      },
      {
        accessorKey: 'created_at',
        header: 'Redeem_date',
        size: 2,
      },
      
    ],
    []
  );

  return (
    <Card sx={{ backgroundColor: 'white' }}>
      <MaterialReactTable
        enableFilters={false}
        columns={columns}
        data={data}
        enableSorting={false}
        // getRowId={(row) => row.phoneNumber}
        initialState={{ showColumnFilters: true }}
        manualPagination
        muiTablePaperProps={{}}
        muiTopToolbarProps={{
          content: 'Customer'
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          // sorting,
        }}
      />
    </Card>
  );
};

export default AppRedeemsTable;
