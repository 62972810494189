import React from 'react'
import { Helmet } from 'react-helmet-async'
// @mui
import { styled } from '@mui/material/styles'
import { Typography, Container } from '@mui/material'
import { useParams } from 'react-router-dom'

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

const RedeemSuccessfulPage = (): JSX.Element => {
  const { id } = useParams()

  return (
    <>
      <Helmet>
        <title> Success | EngagePackage </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant='h3' paragraph>
            Thank you for your redeem!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>reference: {id}</Typography>
        </StyledContent>
      </Container>
    </>
  )
}
export default RedeemSuccessfulPage
