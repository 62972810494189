export interface Customer {
  id?: string
  country_code?: string
  mobile?: string
  gender?: string
  dob?: string
  email?: string
  full_name?: string
  created_at?: string
  updated_at?: string
  last_sale_id?: string
  first_sale?: boolean
  products?: Product[]
  business?: Business
  points?: number
  acquisition_channel?: number
  phone_verification?: number

}
// export interface CustomerAPI extends Customer {
//   business: {
//     id: string
//     name: string
//     icon: string | null
//     google_link: string | null
//   }
// }

export interface CustomerListAPI {
  num_of_pages: number
  count: number
  data: Customer[]
}

export interface Business {
  id?: string
  name?: string
  icon?: string
  google_link?: string
  created_at?: string
  country?: string
  loyalty_model?: PointType
}
export enum Gender {
  M,
  F,
  O,
}
export interface Option {
  label: string
  value: string
}

export interface Product {
  id: number
  name: string
  creacted_at: string
  industry: number
}

export enum PointType {
  Off = 0,
  Points = 1,
  Stamps = 2,
}

export interface Reward {
  id: string
  name: string
  description: string
  cover_photo: string
  loyalty_weight: number
  useable: boolean
  business: Business
  remaining:number
  start_date:string
  end_date:string
  capacity:number
  is_enabled: boolean
  reusable: boolean
}

export interface RewardListAPI {
  num_of_pages: number
  count: number
  data: Reward[]
}

export interface RedeemListAPI {
  num_of_pages: number
  count: number
  data: Redeem[]
}
export interface Redeem {
  id: string
  updated_at: string
  created_at: string
  customer: Customer
  reward: Reward
}

export interface RedeemTable {
  customer_id?: string
  id?: string
  mobile?: string
  gender?: string
  dob?: string
  email?: string
  full_name?: string
  customer_created_at?: string
  customer_updated_at?: string
  last_sale_id?: string
  first_sale?: boolean
  customer_loyalty_weight?: number
  acquisition_channel?: number
  phone_verification?: number
  reward_id: string
  // reward_updated_at: string
  // reward_created_at: string
  remaining:number
  useable: boolean
  name: string
  cover_photo: string
  description: string
  reward_loyalty_weight:number
  start_date: string
  end_date: string
  capacity:number
  reusable : boolean
  is_enabled: boolean
  updated_at: string
  created_at: string
}


