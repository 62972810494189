import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import { useAuth } from "../../hooks/useAuth";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = (): JSX.Element => {
  const { user } = useAuth();

  if (user === null) {
    return <Navigate to="/login" />;
  }


  return (
    <StyledRoot>
      {(user !== null) && <Header />}
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
export default DashboardLayout
