import React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, Card } from '@mui/material';
// hooks
// import { Navigate } from 'react-router-dom';
import useResponsive from 'hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections
import { CustomerLoginForm } from 'sections/auth/login';
import { useCustomerAuth } from 'hooks/useCustomerAuth';
import { Navigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const StyledRoot = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled(Stack)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const CustomerLoginPage = (): JSX.Element => {
  const mdUp = useResponsive('up', 'md');
  console.log('login page');
  const { user } = useCustomerAuth();
  if (user?.id !== '') {
    return <Navigate to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title> Login | EngagePackage </title>
      </Helmet>

      <StyledRoot>
        {
          mdUp &&
          (
            <StyledSection>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome Back
              </Typography>
              <img src="/assets/illustrations/illustration_login.png" alt="login" />
            </StyledSection>
          )}

        <Container maxWidth="sm">
          <StyledContent spacing={2}>
            <CustomerLoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
export default CustomerLoginPage