import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Typography,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
// component
import Rate from 'components/rate/Rate'
import type { Business, Customer } from 'types/customer'
import Datepicker from 'components/datepicker'

import requestInstance from 'utils/request'
import moment from 'moment'
import { AppCountrySelect } from 'sections/@dashboard/app'
import countries from '_mock/countries'
import { type salesRecord } from 'types/salesRecord'
import { enqueueSnackbar } from 'notistack'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  // textAlign: 'center',
  alignItems: 'center',
  // color: theme.palette.text,
}))

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  height: 'auto',
  px: 2,
  py: 3,
  // boxShadow: 0,
  // textAlign: 'center',
  // color: (theme) => theme.palette[color].darker,
  // bgcolor: (theme) => theme.palette[color].lighter,
}))
const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 0,
  paddingRight: 0
}))

interface SurveyCustomerStepperProps {
  business: Business
  localCustomer: Customer
  approval: string

}

const SurveyCustomerStepper = ({ business, localCustomer, approval }: SurveyCustomerStepperProps): JSX.Element => {
  const [nextLoadingButton, setNextLoadingButton] = useState<boolean>(false)
  // const [backLoadingButton, setBackLoadingButton] = useState<boolean>(false)

  // profile states
  const [name, setName] = useState<string>(localCustomer !== null ? localCustomer.full_name ?? '' : '')
  const [mobile, setMobile] = useState<string>(localCustomer !== null ? localCustomer.mobile ?? '' : '')
  const [dob, setDob] = useState<string | ''>(
    localCustomer !== null ? moment(localCustomer.dob).isValid() ? moment(localCustomer.dob).format('YYYY-MM-DD') : '' : ''
  )
  const [gender, setGender] = useState<string>(localCustomer !== null ? localCustomer.gender ?? '' : '')
  const [email, setEmail] = useState<string>(localCustomer !== null ? localCustomer.email ?? '' : '')
  const [acqiusition, setAcqiusition] = useState<string>(localCustomer !== null && localCustomer.acquisition_channel !== null ? `${localCustomer.acquisition_channel}` ?? '' : '')

  const [countryPhone, setCountryPhone] = useState<string>(localCustomer !== null ?
    localCustomer.country_code ?? countries[0].phone : countries.find((item) => business.country === item.code)?.phone ?? countries[0].phone
  )
  const [saleId, setSaleId] = useState<number>()
  const [customer, setCustomer] = useState<Customer | null>(null)
  // const []

  //  Rate states

  const [description, setDescription] = useState<string>('')
  const [rate, setRate] = useState<number>(-1)
  const [loadingButton, setLoadingButton] = useState(false)
  const [surveyDone, setSurveyDone] = useState<boolean>(false)

  // theme
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [open, setOpen] = useState<boolean>(false)

  //  stepper
  const [activeStep, setActiveStep] = useState<number>(0)
  // const [rate, setRate] = useState<number>(-1)
  const [skipped, setSkipped] = useState(new Set())
  const handleRateChange = (rate: number): void => {
    requestInstance
      .post<salesRecord>(`sale/customer/`, {
        approval,
        rate,
        ...(customer !== null && { customer }),
        business: business.id,
      })
      .then((res) => {
        if (res.status === 201) {
          setRate(rate)
          setSaleId(res.data.id)
          if (customer !== null) {
            localStorage.setItem('surveyCustomer', JSON.stringify({ id: res.data.customer.id }))

          }
          // seSaleId(res.data.id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmit = (): void => {
    setLoadingButton(true)
    Boolean(saleId) &&
      requestInstance
        .patch(`sale/${saleId}/`, {
          description,
        })
        .then((res) => {
          if (res.status === 200) {
            // enqueueSnackbar('your opinion submitted successfuly', { variant: 'success' })
            setSurveyDone(true)
          }
          setLoadingButton(false)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setLoadingButton(false)
        })
  }
  const isStepSkipped = (step: number): boolean => {
    return skipped.has(step)
  }
  const isStepOptional = (step: number): boolean => {
    return step === 0
  }
  const clearCustomer = (): void => {
    localStorage.removeItem('surveyCustomer')
    setName('')
    setMobile('')
    setDob('')
    setCountryPhone(countries.find((item) => business.country === item.code)?.phone ?? countries[0].phone)
    setEmail('')
    setGender('')
    setAcqiusition('')
  }

  const disabledFields = {
    country: Boolean(localStorage.getItem('surveyCustomer')) && localCustomer !== null,
    tel: Boolean(localStorage.getItem('surveyCustomer')) && localCustomer !== null,
    email: Boolean(localStorage.getItem('surveyCustomer')) && localCustomer !== null && localCustomer.email === null,
    dob: Boolean(localStorage.getItem('surveyCustomer')) && localCustomer !== null && localCustomer.dob === null,
    acqiusition: Boolean(localStorage.getItem('surveyCustomer')) && localCustomer !== null && localCustomer.acquisition_channel === null,
    // gender: localCustomer !== null && localCustomer.gender === null
    // localCustomer.gender === ''
  }
  const nextHandler = (): void => {
    setNextLoadingButton(true)

    if (activeStep === 0) {
      if ((dob !== '' || gender !== '' || email !== '' || acqiusition !== null) && (name === '' || mobile === '')) {
        enqueueSnackbar('mobile and name are required!', { variant: 'error' })
        setNextLoadingButton(false)
        return
      }
      if (name === '' || mobile === '') {
        setCustomer(null)
        setNextLoadingButton(false)
        setActiveStep((prev) => prev + 1)
        return
      }
      setCustomer({
        ...(name !== '' && { full_name: name }),
        ...(mobile !== '' && { mobile }),
        ...(dob !== '' && { dob: moment(dob).format('YYYY-MM-DD') }),
        ...(gender !== '' && { gender }),
        ...(email !== '' && { email }),
        ...(countryPhone !== '' && { country_code: countryPhone }),
        ...(acqiusition !== '' && { acquisition_channel: Number(acqiusition) }),

      })
      setNextLoadingButton(false)
      setActiveStep((prev) => prev + 1)
    }
  }
  const handleSkip = (): void => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }
  const SurveyProfileForm = (
    <>
      <Typography
        variant='body1'
        sx={{ color: 'text.secondary', textAlign: 'center' }}>
        Please complete your profile to receive your unique personalized suggestions.
      </Typography>

      <form
        autoComplete='off'
      // onSubmit={() => { onSubmit(10) }}
      >
        <Grid
          sx={{ padding: '15px 0' }}
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Grid flexDirection={'row'} spacing={2} display={'flex'} container flexWrap={'nowrap'}>
              <Grid item xs={5} sm={4}>
                <AppCountrySelect countryPhone={countryPhone} onChange={setCountryPhone} disabled={disabledFields.country} />
              </Grid>
              <Grid item xs={7} sm={8}>
                <TextField
                  type='tel'
                  label="Mobile"
                  inputProps={{
                    type: 'tel',
                  }}
                  value={mobile}
                  fullWidth
                  onChange={(event) => {
                    setMobile(event.target.value)
                  }}
                  disabled={disabledFields.tel}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <FormLabel id='demo-row-radio-buttons-group-label' sx={{ textAlign: 'left' }}>
                Title (optional)
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={gender}
                onChange={(event) => {
                  setGender(event.target.value)
                }}
              >
                <FormControlLabel value='M' control={<Radio />} label='Mr.' />
                <FormControlLabel value='F' control={<Radio />} label='Mrs.' />
                <FormControlLabel value='O' control={<Radio />} label='Other' />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <TextField
                key='fullName'
                name='fullName'
                label='Name'
                fullWidth
                autoComplete='none'
                value={name}
                onChange={(event) => {
                  setName(event.target.value)
                }}
              />
            </FormControl>
          </Grid>

          {disabledFields.dob && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Datepicker
              open={open}
              handleClose={() => { setOpen(false) }}
              handleOpen={() => { setOpen(true) }}
              label='Date of Birth'
              onChange={setDob}
              value={dob}
            />

          </Grid>
          }

          {disabledFields.email && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <TextField
                key='email'
                name='email'
                label='Email (optional)'
                fullWidth
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                autoComplete='none'
                type='email'

              />
            </FormControl>
          </Grid>}
          {disabledFields.acqiusition && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-hear-label'>How did you first hear about us? (optional)</InputLabel>
              <Select
                labelId='demo-simple-hear-label'
                // id='demo-simple-select'
                value={acqiusition}
                label='How did you first hear about us? (optional)'
                onChange={(event) => {
                  setAcqiusition(event.target.value)
                }}
                fullWidth
              >
                <MenuItem value={1}>Google search</MenuItem>
                <MenuItem value={2}>Social media</MenuItem>
                <MenuItem value={3}>Walk-in</MenuItem>
                <MenuItem value={4}>Friends</MenuItem>
                <MenuItem value={5}>Online advertisements</MenuItem>
                <MenuItem value={6}>Offline advertisements</MenuItem>
              </Select>
            </FormControl>
          </Grid>}
          {activeStep === 0 && localCustomer !== null && Boolean(localStorage.getItem('surveyCustomer')) &&
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
              <Typography
                onClick={clearCustomer}
                sx={{
                  cursor: 'pointer',
                  color: 'text.secondary',
                  width: '100%',
                  textAlign: 'center',

                }}>
                This isn&apos;t you?
              </Typography>
            </Grid>
          }
        </Grid>
      </form>
    </>
  )

  const SurveyRateForm = (
    <>
      {surveyDone ? (
        <Typography

          variant='body1'
          sx={{
            mt: 2,
            mb: 1,
            textAlign: 'center',
            color: 'text.secondary'
          }}>
          Thank you for your time.
        </Typography>
      ) : (
        <Box>
          <Collapse in={rate < 0} timeout='auto' unmountOnExit>
            <Typography
              variant='body1'
              sx={{ color: 'text.secondary', textAlign: 'center' }}>
              From 0 to 10, how likely are you to recommend us to a friend or colleague?
            </Typography>
            {/* <Rate value={rate} setter={setRate} lastSaleId={lastSaleId} /> */}
            <Rate value={rate} onChange={handleRateChange} />
            {/* <Divider variant="middle" /> */}
          </Collapse>

          <Collapse in={rate >= 0} timeout='auto'>
            {rate < 7 && (
              <Box>
                <Typography
                  variant='body1'
                  sx={{ color: 'text.secondary', textAlign: 'center' }}>
                  What are the main reasons for your satisfaction or dissatisfaction?
                </Typography>
                <TextField
                  multiline
                  rows={6}
                  placeholder='Write here...'
                  sx={{ padding: '15px 0', width: '100%' }}
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value)
                  }}
                />
                <CardActions sx={{ px: 0 }}>
                  <LoadingButton
                    loading={loadingButton}
                    sx={{ width: '100%' }}
                    size='large'
                    variant='contained'
                    onClick={handleSubmit}
                  >
                    Submit
                  </LoadingButton>
                </CardActions>
              </Box>
            )}
            {rate >= 7 && (
              <Box>
                <Typography
                  sx={{ mt: 2, mb: 1, color: 'text.secondary', textAlign: 'center' }}
                  variant='body1'>
                  Thank you for your time.
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ color: 'text.secondary', textAlign: 'center' }}>
                  Please leave us a review on Google and tell your most satisfaction reasons.
                </Typography>
                {/* <StyledCardActions> */}
                <CardActions sx={{ marginTop: '16px' }}>

                  <Button
                    sx={{ width: '100%' }}
                    size='large'
                    variant='contained'
                    onClick={() => window.open(business?.google_link, '_self')}
                  >
                    Open Google
                  </Button>
                </CardActions>
                {/* </StyledCardActions> */}
              </Box>
            )}
          </Collapse>
        </Box>
      )}
    </>
  )
  const steps = [
    {
      label: 'Profile',
      component: SurveyProfileForm,
    },

    {
      label: 'Survey',
      component: SurveyRateForm,
    },
  ]
  return (
    <>
      <StyledContent>
        <Avatar src={business.icon} alt='photoURL' sx={{ width: 96, height: 96 }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            {business.name}
          </Typography>
        </Box>

        <StyledCard>
          <CardContent>
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={step.label} {...stepProps}>
                    <StepLabel
                    // optional={
                    //   isStepOptional(index) ? (
                    //     <Typography variant='caption'>Optional</Typography>
                    //   ) : (
                    //     <></>
                    //   )
                    // }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            <Box sx={{ marginTop: '16px' }}>

              {steps[activeStep].component}
              {activeStep >= 0 && activeStep < steps.length - 1 ? (
                <StyledCardActions>
                  <LoadingButton
                    loading={nextLoadingButton}
                    sx={{ width: matches ? 'fit-content' : '100%' }}
                    onClick={nextHandler}
                    variant='contained'
                    size='large'
                  >
                    Next
                  </LoadingButton>
                  {activeStep > 0 && (
                    <LoadingButton
                      // loading={backLoadingButton}
                      sx={{ width: matches ? 'fit-content' : '100%', justifySelf: 'flex-start' }}
                      // onClick={() => {

                      // }}
                      variant='outlined'
                      size='large'
                      color='secondary'
                    >
                      back
                    </LoadingButton>
                  )}


                  {isStepOptional(activeStep) && (
                    <Button
                      size='large'
                      color='inherit'
                      onClick={handleSkip}
                      sx={{ width: matches ? 'fit-content' : '100%' }}
                      variant='outlined'>
                      Skip
                    </Button>
                  )}
                </StyledCardActions>
              ) : (
                <></>
              )}
            </Box>
          </CardContent>
        </StyledCard>

        <Box sx={{ textAlign: 'center', marginTop: 'auto', marginBottom: '50px' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default SurveyCustomerStepper
