import React from 'react'
import { useLoaderData } from 'react-router-dom'
// import SurveyAnonymousStepper from 'sections/survey/SurveyAnonymousStepper'
// import SurveyCustomerForm from 'sections/survey/SurveyCustomerStepper'
import { type Customer, type Business } from 'types/customer'
import SurveyBusinessStepper from 'sections/survey/SurveyBusinessStepper'
import SurveyCustomerStepper from 'sections/survey/SurveyCustomerStepper'
import SurveyOptionalProfileStepper from 'sections/survey/SurveyOptionalProfileStepper'
import SurveyEmptyStepper from 'sections/survey/SurveyEmptyStepper'
const renderedComponent = (type: string, business: Business, customer: Customer, approval: string): JSX.Element => {
  if (type === 'anonymous') return <SurveyOptionalProfileStepper business={business} localCustomer={customer} />
  if (type === 'empty') return <SurveyEmptyStepper business={business} />
  if (type === 'business') return <SurveyBusinessStepper business={business} customer={customer} />
  if (type === 'customer') return <SurveyCustomerStepper business={business} localCustomer={customer} approval={approval} />
  return <div>nothing correct happend</div>
}

const SurveyPage = (): JSX.Element => {
  const state = useLoaderData() as { type: string; business: Business; customer: Customer, approval: string }
  console.log('user in storage:')
  console.log(state.customer)
  return <>{renderedComponent(state.type, state.business, state.customer, state.approval)}</>
}
export default SurveyPage
