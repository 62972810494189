import React, { useState, useEffect } from 'react'
import { Box, Typography, Card, CardContent, Stack, IconButton, Tooltip, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCustomerAuth } from 'hooks/useCustomerAuth'
import moment from 'moment'
import { PointType } from 'types/customer'
import { type CustomerUser } from 'types/auth'
import requestInstance from 'utils/request'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  height: 'auto',
  px: 2,
  py: 3,
}))


const CustomerProfileCard = (): JSX.Element => {
  const { user, logout } = useCustomerAuth()
  const [profile, setProfile] = useState<CustomerUser>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    requestInstance.get<CustomerUser>(`/customer/${user?.id}/?is_profile=true`).then(res => {
      setProfile(res.data)
    }
    ).catch(err => {
      console.log(err)
    }).finally(() => { setIsLoading(false) }
    )

  }, []);


  return (
    <>
      <StyledCard>
        <CardContent sx={{ width: '100%' }}>
          {isLoading ? <CircularProgress /> : <Stack spacing={2}>
            <Box sx={{ textAlign: 'left', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h5'>{profile?.full_name}</Typography>

              <Tooltip title="Logout" >
                <IconButton onClick={logout} sx={{ marginRight: '-3px', padding: 0 }}>
                  <PowerSettingsNewIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant='subtitle2'>Since {moment(profile?.created_at).format(`DD-MM-YYYY`)}</Typography>

              <Typography
                variant='subtitle2'
              >
                {profile?.loyalty_weight} {PointType[profile?.business?.loyalty_model ?? 1]}
              </Typography>
            </Box>
          </Stack>}
        </CardContent>
      </StyledCard>
    </>
  )
}

export default CustomerProfileCard
