import React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LoginSuccessfulPage = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title> All Done | EngagePackage </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            The login link was sent to you!

          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Click on the received SMS link to log in to your loyalty profile.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_done.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />
        </StyledContent>
      </Container>
    </>
  );
}
export default LoginSuccessfulPage