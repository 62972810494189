import React from "react"
import { Navigate, useLoaderData } from "react-router-dom"
import { useCustomerAuth } from "hooks/useCustomerAuth"
import { type CustomerUser } from "types/auth"

const OneTimePassword = (): JSX.Element => {
    const { customer, redirect } = useLoaderData() as { customer: CustomerUser, redirect: string | null }
    const { login } = useCustomerAuth()
    login(customer)
    console.log('login: ')
    console.log(customer)
    console.log(redirect)
    localStorage.setItem('customerUser', JSON.stringify(customer))
    if (redirect === null || redirect === undefined) {
        return <Navigate  to={'/profile'} />
    }
    else {
        return <Navigate to={redirect} />

    }

}


export default OneTimePassword