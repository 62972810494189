
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Card } from '@mui/material';
import moment from 'moment';
import requestInstance from 'utils/request';
import type { Customer, CustomerListAPI } from 'types/customer';
// @mui

// ----------------------------------------------------------------------
// for enable sort, filter and global filter features, uncomment those sections


const AppCustomersTable = ({ id }: { id: string }): JSX.Element => {
  
  //  data and fetching state
  const [data, setData] = useState<Customer[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //  table state
  // const [columnFilters, setColumnFilters] = useState([]);
  // const [globalFilter, setGlobalFilter] = useState('');
  // const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    requestInstance.get<CustomerListAPI>(
      `/customer?business=${id}&per_page=${pagination.pageSize}&page=${pagination.pageIndex + 1}`
    ).then(res => {
      const json = res.data;
      const list = json.data.map((item) => {
        const customer: Customer = {
          full_name: item.full_name ?? '-',
          mobile: `+${item.country_code}${item.mobile}` ?? '-',
          created_at: moment(item.created_at).format('YYYY-MM-DD HH:mm') ?? '-',
          email: item.email ?? '-',
          dob: item.dob ?? '-',
          gender: item.gender ?? '-',
        }
        return customer
      });
      setData(list);
      setRowCount(json.count);

    }).catch(err => {
      setIsError(true);
      console.error(err);
    });
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // columnFilters,
    // globalFilter,
    // sorting,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const columns = useMemo<Array<MRT_ColumnDef<Customer>>>(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Name',
        size: 2,
      },
      {
        accessorKey: 'mobile',
        header: 'Mobile',
        size: 2,
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 2,
      },
      {
        accessorKey: 'dob',
        header: 'Dob',
        size: 2,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 2,
      },
      {
        accessorKey: 'products',
        header: 'Products',
        size: 2,
      },

      {
        accessorKey: 'created_at',
        header: 'Since',
        size: 2,
      },

    ],
    []
  );

  return (
    <Card sx={{ backgroundColor: 'white' }}>
      <MaterialReactTable
        enableFilters={false}
        columns={columns}
        data={data}
        enableSorting={false}
        // getRowId={(row) => row.phoneNumber}
        initialState={{ showColumnFilters: true }}
        manualPagination
        muiTablePaperProps={{}}
        muiTopToolbarProps={{
          content: 'Customer'
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data',
            }
            : undefined
        }
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          // sorting,
        }}
      />
    </Card>
  );
};

export default AppCustomersTable;
