import React, { useState, useEffect } from 'react'
import { Avatar, Box, Typography, Stack, Divider } from '@mui/material'

import { styled } from '@mui/material/styles'
// component
import { CustomerProfileCard, CustomerProfileRewardCard } from 'sections/customerProfile'
import { useCustomerAuth } from 'hooks/useCustomerAuth'

import type { Reward, RewardListAPI, Business } from 'types/customer'
import requestInstance from 'utils/request'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
}))
const CustomerProfile = (): JSX.Element => {
  const { user } = useCustomerAuth()
  const business: Business | undefined = user?.business

  //  data and fetching state
  const [data, setData] = useState<Reward[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(true);
    }
    requestInstance.get<RewardListAPI>(
      `/reward/?business=${business?.id}&customer=${user?.id}&per_page=100&page=1`
    ).then(res => {
      setData(res.data.data);

    }
    ).catch((err) => {
      setIsError(true);
      console.error(err);
    });
    setIsError(false);
    setIsLoading(false);
  }, [

  ]);

  return (
    <>
      <StyledContent>
        <Avatar
          src={business?.icon}
          alt={`${business?.icon}`}
          sx={{ width: 96, height: 96 }}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
            {business?.name}
          </Typography>
        </Box>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <CustomerProfileCard />
          <Divider flexItem>REWARDS</Divider>
          {!isError && !isLoading && data.map(reward => {
            return <CustomerProfileRewardCard reward={reward} key={reward.id} loyaltymodel={business?.loyalty_model} />
          })}
        </Stack>
        <Box sx={{ textAlign: 'center', marginTop: 'auto', marginBottom: '50px' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
    </>
  )
}
export default CustomerProfile
