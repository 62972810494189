import React, { useState } from 'react'
import {
    Avatar,
    Box,
    Typography,
    Card,
    CardContent,
    Collapse,
    CardActions,
    Button,
    TextField
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
// component
import Rate from 'components/rate/Rate'
import type { Business } from 'types/customer'

import requestInstance from 'utils/request'
import { type salesRecord } from 'types/salesRecord'

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(1, 1),
    // textAlign: 'center',
    alignItems: 'center',
    // color: theme.palette.text,
}))

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    height: 'auto',
    px: 2,
    py: 3,

}))

interface SurveyOptionalProfileStepperProps {
    business: Business
}

const SurveyEmptyStepper = ({
    business,

}: SurveyOptionalProfileStepperProps): JSX.Element => {
    const [saleId, setSaleId] = useState<number>()

    //  Rate states

    const [description, setDescription] = useState<string>('')
    const [rate, setRate] = useState<number>(-1)
    const [loadingButton, setLoadingButton] = useState(false)
    const [surveyDone, setSurveyDone] = useState<boolean>(false)

    const handleRateChange = (rate: number): void => {
        requestInstance
            .post<salesRecord>(`sale/customer/`, {
                rate,
                business: business.id,
            })
            .then((res) => {
                if (res.status === 201) {
                    setRate(rate)
                    setSaleId(res.data.id)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleSubmit = (): void => {
        setLoadingButton(true)
        Boolean(saleId) &&
            requestInstance
                .patch(`sale/${saleId}/`, {
                    description,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setSurveyDone(true)
                    }
                    setLoadingButton(false)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoadingButton(false)
                })
    }


    const SurveyRateForm = (
        <>
            {surveyDone ? (
                <Typography
                    sx={{ mt: 2, mb: 1, color: 'text.secondary', textAlign: 'center' }}
                    variant='body1'>
                    Thank you for your time.
                </Typography>
            ) : (
                <Box>
                    {/* <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}> */}
                    <Collapse in={rate < 0} timeout='auto' unmountOnExit>
                        <Typography
                            variant='body1'
                            sx={{ color: 'text.secondary', textAlign: 'center' }}>
                            From 0 to 10, how likely are you to recommend us to a friend or colleague?
                        </Typography>
                        {/* <Rate value={rate} setter=,{setRate} lastSaleId={lastSaleId} /> */}
                        <Rate value={rate} onChange={handleRateChange} />
                        {/* <Divider variant="middle" /> */}
                    </Collapse>

                    <Collapse in={rate >= 0} timeout='auto'>
                        {rate < 7 && (
                            <Box>
                                <Typography
                                    variant='body1'
                                    sx={{ color: 'text.secondary', textAlign: 'center' }}>
                                    What are the main reasons for your satisfaction or dissatisfaction?
                                </Typography>
                                <TextField
                                    multiline
                                    rows={6}
                                    placeholder='Write here...'
                                    sx={{ padding: '15px 0', width: '100%' }}
                                    value={description}
                                    onChange={(event) => {
                                        setDescription(event.target.value)
                                    }}
                                />
                                <CardActions sx={{ px: 0 }}>
                                    <LoadingButton
                                        loading={loadingButton}
                                        sx={{ width: '100%' }}
                                        size='large'
                                        variant='contained'
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </LoadingButton>
                                </CardActions>
                            </Box>
                        )}
                        {rate >= 7 && (
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{ mt: 2, mb: 1, color: 'text.secondary' }}
                                    variant='body1'>Thank you for your time.</Typography>
                                <Typography
                                    sx={{ color: 'text.secondary' }}
                                    variant='body1'>

                                    Please leave us a review on Google and tell your most satisfaction reasons.
                                </Typography>
                                <CardActions sx={{ marginTop: '16px' }}>

                                    <Button
                                        sx={{ width: '100%' }}
                                        size='large'
                                        variant='contained'
                                        onClick={() => window.open(business?.google_link, '_self')}
                                    >
                                        Open Google
                                    </Button>
                                </CardActions>
                            </Box>
                        )}
                    </Collapse>
                    {/* </CardContent> */}
                </Box>
            )}
        </>
    )

    return (
        <>
            <StyledContent>
                <Avatar src={business.icon} alt='photoURL' sx={{ width: 96, height: 96 }} />
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
                        {business.name}
                    </Typography>
                </Box>

                <StyledCard>
                    <CardContent>
                        {SurveyRateForm}
                    </CardContent>
                </StyledCard>


                <Box sx={{ textAlign: 'center', marginTop: 'auto', marginBottom: '50px' }}>
                    <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
                        Powered by EngagePackage.com
                    </Typography>
                </Box>
            </StyledContent>
        </>
    )
}
export default SurveyEmptyStepper
