import React, { useState } from 'react'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import axios from 'axios'
import moment, { type Moment } from 'moment'
import { Helmet } from 'react-helmet-async'
import makeAnimated from 'react-select/animated'
import { type Customer, type Option, type Product } from 'types/customer'
import requestInstance from 'utils/request'
import Datepicker from 'components/datepicker'

import {
  Card,
  Button,
  Stack,
  FormControl,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Container,
  useMediaQuery,
  useTheme,
  type SxProps,
} from '@mui/material'
import { useNavigate, useLoaderData } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import AsyncSelect from 'react-select/async'
import { type SingleValue } from 'react-select'

const loadProducts = async (id: string): Promise<Option[]> => {
  const url = `product?industry__business=${id}&per_page=300`
  return await axios.get(`${url}`).then((response) => {
    return response.data.data.map((item: Product) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  })
}
interface CustomerPageProps {
  sx?: SxProps
}
const CustomerPage = ({ sx }: CustomerPageProps): JSX.Element => {
  const animatedComponents = makeAnimated()
  const loaderData = useLoaderData() as Customer
  const [customer, setCustomer] = useState<Customer>(loaderData)
  const navigate = useNavigate()
  const [datetime, setDatetime] = useState<Moment | null>(null)
  const [loadingButton, setLoadingButton] = useState(false)
  const [productList, setProductList] = useState<SingleValue<Option>>()
  const [amount, setAmount] = useState('')
  const [open, setOpen] = useState<boolean>(false)
  const [datetimeOpen, setDatetimeOpen] = useState<boolean>(false)
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [dob, setDob] = useState<string | ''>(
    moment(customer?.dob).isValid() ? moment(customer.dob).format('YYYY-MM-DD') : '',
  )
  console.log('dob')
  console.log(theme.palette.grey)
  console.log(customer?.dob)
  console.log(moment(customer?.dob).isValid())
  // const dispatch = useAppDispatch()
  const handleSave = (): void => {
    setLoadingButton(true)

    void requestInstance
      .post(`/sale/customer/`, {
        products: productList === undefined ? [] : [productList?.value],
        delivery_date: datetime !== null ? datetime.format('YYYY-MM-DDTHH:mm:ss') : null,
        amount: amount !== '' ? amount : null,
        customer: {
          ...{
            ...((dob !== '' && dob !== null) && { dob }),
            ...(customer.gender !== '' && { gender: customer.gender }),
            ...(customer.email !== '' && { email: customer.email }),
            mobile: customer.mobile,
            full_name: customer.full_name,
            country_code: customer.country_code,
            business: customer.business,
          },
          business: customer.business?.id,
        },
        business: customer.business?.id,
        is_dashboard: true,
      })
      .then((res) => {
        if (res.status === 201) {
          navigate(`/dashboard`)
        }
      })
      .catch((error) => {
        return error
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }
  console.log('customer in customer page')
  console.log(customer.dob)
  console.log(dob)
  return (
    <>
      {
        <>
          <Helmet>
            <title> Sale | EngagePackage </title>
          </Helmet>

          <Container maxWidth='xl'>
            <Grid container spacing={3} justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <Card
                    elevation={5}
                    sx={{
                      px: 4,
                      py: 5,
                      textAlign: 'center',
                      ...sx,
                    }}
                  >
                    <form autoComplete='off'>
                      <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              key='mobile'
                              name='mobile'
                              label='Mobile'
                              fullWidth
                              autoComplete='none'
                              value={`+${customer.country_code}${customer.mobile}`}
                              disabled
                            />
                          </FormControl>
                        </Grid>
                        {matches && <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>

                        </Grid>}

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <FormLabel
                              id='demo-row-radio-buttons-group-label'
                              sx={{ textAlign: 'left' }}
                            >
                              Title (optional)
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby='demo-row-radio-buttons-group-label'
                              name='row-radio-buttons-group'
                              value={customer.gender}
                              onChange={(event) => {
                                setCustomer((prev) => {
                                  const newCustomer = { ...prev }
                                  newCustomer.gender = event.target.value
                                  return newCustomer
                                })
                              }}
                            >
                              <FormControlLabel value='M' control={<Radio />} label='Mr.' />
                              <FormControlLabel value='F' control={<Radio />} label='Mrs.' />
                              <FormControlLabel value='O' control={<Radio />} label='Other' />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              key='fullName'
                              name='fullName'
                              label='Name'
                              fullWidth
                              autoComplete='none'
                              value={customer.full_name}
                              onChange={(newValue) => {
                                setCustomer((prev) => {
                                  const newCustomer = { ...prev }
                                  newCustomer.full_name = newValue.target.value
                                  return newCustomer
                                })
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Datepicker
                            open={open}
                            handleClose={() => {
                              setOpen(false)
                            }}
                            handleOpen={() => {
                              setOpen(true)
                            }}
                            label='Date of Birth'
                            value={dob}
                            onChange={setDob}
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              key='email'
                              name='email'
                              label='Email (optional)'
                              fullWidth
                              autoComplete='none'
                              value={customer.email}
                              onChange={(newValue) => {
                                setCustomer((prev) => {
                                  const newCustomer = { ...prev }
                                  newCustomer.email = newValue.target.value
                                  return newCustomer
                                })
                              }}
                              type='email'

                            />
                          </FormControl>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <AsyncSelect
                              components={animatedComponents}
                              menuPortalTarget={document.body}
                              theme={(themee) => ({
                                ...themee,
                                borderRadius: 0,
                                colors: {
                                  ...themee.colors,
                                  primary: theme.palette.primary.main,
                                  neutral20: theme.palette.grey[500], // regular color
                                  neutral30: '#212B36', // hovered color
                                },
                              })}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: '6px',
                                  padding: '9px 5px',
                                  textAlign: 'left',
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              isMulti={false}
                              defaultOptions
                              value={productList}
                              cacheOptions
                              loadOptions={async () => {
                                return await loadProducts(customer.business?.id ?? '')
                              }}
                              placeholder='Products (optional)'
                              onChange={(newval) => {
                                console.log(newval)
                                setProductList(newval)
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              id='outlined-amount'
                              type='number'
                              label='Amount in AED (optional)'
                              aria-describedby='filled-weight-helper-text'
                              inputProps={{
                                'aria-label': 'amount',
                              }}
                              sx={{ width: '100%' }}
                              value={amount}
                              onChange={(event) => {
                                setAmount(event.target.value)                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <DateTimePicker
                              open={datetimeOpen}
                              onOpen={() => { setDatetimeOpen(true) }}
                              onClose={() => { setDatetimeOpen(false) }}
                              format="DD-MMM-YYYY HH:mm A"
                              label='Delivery date & time (optional)'
                              value={datetime}
                              onChange={(newValue) => {
                                setDatetime(newValue)
                              }}

                              // slotProps={{
                              //   textField: {
                              //     onclick: (e) => setOpen(true)
                              //   }
                              // }}
                              slotProps={{
                                textField: { onClick: () => { setDatetimeOpen(true) } }
                              }}
                            // renderInput={(params) => {
                            //   return (
                            //     <TextField
                            //       {...params}
                            //       onClick={(e) => setOpen(true)}
                            //     />
                            //   );
                            // }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          display={'flex'}
                          justifyContent={'space-between'}
                          flex={2}
                        >
                          <Button
                            color='secondary'
                            size='large'
                            variant='outlined'
                            sx={{ flex: 1, margin: '8px', marginLeft: 0 }}
                            onClick={() => {
                              navigate('/dashboard')
                            }}
                          >
                            Back
                          </Button>
                          <LoadingButton
                            loading={loadingButton}
                            sx={{ flex: 1, margin: '8px', marginRight: 0 }}
                            variant='contained'
                            size='large'
                            onClick={handleSave}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </form>
                  </Card>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </>
      }
    </>
  )
}
export default CustomerPage
