import { type CustomerUser } from "types/auth";
import React ,{ createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage  from "./useLocalStorage";


interface CustomerAuthContextType  {
    user: CustomerUser |null ;
    login: (user:CustomerUser) => void;
    logout: () => void;
  };
  
  
  export const CustomerAuthContext = createContext<CustomerAuthContextType>({
    user:{
      username:'',
      avatar:'',
      token:'',
      email:'',
      id:''
    },
    login:()=>{},
    logout:()=>{}
  });
  
  
  export const CustomerAuthProvider  = ({ children, userData }:{children:React.ReactNode , userData:CustomerUser | null}):JSX.Element => {
    const [user, setUser] = useLocalStorage<CustomerUser | null>("customerUser", userData);
    console.log('userData')
    console.log(userData)
    const navigate = useNavigate();
  
    const login =  (data:CustomerUser):void => {
      setUser(data);
    };
  
    const logout = ():void => {
      localStorage.removeItem('customerUser')
      // setUser(null);

      navigate("/customerlogin", { replace: true });
    };
    const u:CustomerUser|null = user 
    const value = useMemo(
      () => ({
        user :u,
        login,
        logout
      }),
      [user]
    );
      // return <AuthContext></AuthContext>
    return <CustomerAuthContext.Provider value={value}>{children}</CustomerAuthContext.Provider>;
  };
  
  export const useCustomerAuth = ():CustomerAuthContextType => {
    return useContext(CustomerAuthContext);
  };
  