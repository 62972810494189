import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Typography,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  FormControl,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
// component
import Rate from 'components/rate/Rate'
import type { Business, Customer } from 'types/customer'

import requestInstance from 'utils/request'
import moment from 'moment'
import { AppCountrySelect } from 'sections/@dashboard/app'
import Datepicker from 'components/datepicker'

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  textAlign: 'center',
  alignItems: 'center',
  // color: theme.palette.text,
}))

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  height: 'auto',
  px: 2,
  py: 3,
  // boxShadow: 0,
  textAlign: 'center',
  // color: (theme) => theme.palette[color].darker,
  // bgcolor: (theme) => theme.palette[color].lighter,
}))
const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

interface SurveyBusinessStepperProps {
  business: Business
  customer: Customer
}

const SurveyBusinessStepper = ({ business, customer }: SurveyBusinessStepperProps): JSX.Element => {
  const [nextLoadingButton, setNextLoadingButton] = useState<boolean>(false)
  // const [backLoadingButton, setBackLoadingButton] = useState<boolean>(false)

  // profile states
  const [name, setName] = useState<string>(customer.full_name ?? '')
  const [acqiusition, setAcqiusition] = useState<number | ''>('')
  const [dob, setDob] = useState<string | ''>(
    customer !== null ? moment(customer.dob).isValid() ? moment(customer.dob).format('YYYY-MM-DD') : '' : ''
  )
  const [gender, setGender] = useState<string>(customer.gender ?? '')
  const [email, setEmail] = useState<string>(customer.email ?? '')

  //  Rate states

  const [description, setDescription] = useState<string>('')
  const [rate, setRate] = useState<number>(-1)
  const [loadingButton, setLoadingButton] = useState(false)
  const [surveyDone, setSurveyDone] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  // theme
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  //  stepper
  const [activeStep, setActiveStep] = useState<number>(0)

  const disabledFields = {
    email: (customer.first_sale ?? false) ? false : (customer.email === null || customer.email === ''),
    dob: (customer.first_sale ?? false) ? false : customer.dob === null || customer.dob === '',
    acqiusition: customer.acquisition_channel === null,
    gender: true,
  }
  const handleRateChange = (rate: number): void => {
    requestInstance
      .patch(`sale/${customer.last_sale_id}/`, {
        rate,
        // customer: customerId,
        business: business.id,
      })
      .then((res) => {
        if (res.status === 200) {
          setRate(rate)
          // seSaleId(res.data.id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmit = (): void => {
    setLoadingButton(true)

    requestInstance
      .patch(`sale/${customer.last_sale_id}/`, {
        description,
      })
      .then((res) => {
        if (res.status === 200) {
          // enqueueSnackbar('your opinion submitted successfuly', { variant: 'success' })
          setSurveyDone(true)
        }
        setLoadingButton(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }
  // const isStepSkipped = (step: number): boolean => {
  //   return skipped.has(step)
  // }
  const isStepOptional = (step: number): boolean => {
    return false
  }

  const nextHandler = (): void => {
    setNextLoadingButton(true)
    if (activeStep === 0) {
      requestInstance
        .patch(`customer/${customer.id}/`, {
          ...(name !== '' && { full_name: name }),
          ...(customer.mobile !== '' && { mobile: customer.mobile }),
          ...(dob !== '' && { dob }),
          ...(gender !== '' && { gender }),
          ...(email !== '' && { email }),
          ...(customer.country_code !== '' && { country_code: customer.country_code }),
          ...(acqiusition !== null && acqiusition !== '' && { acquisition_channel: acqiusition }),

          business: business.id,
        })
        .then((res) => {
          setNextLoadingButton(false)
          setActiveStep((prev) => prev + 1)
        })
        .catch((err) => {
          console.log(err)
          setNextLoadingButton(false)
          // throw err
        })
    }
  }
  const SurveyProfileForm = (
    <>
      <Typography
        variant='body1'
        sx={{ color: 'text.secondary' }}>
        Please review and complete your profile to receive your unique personalized suggestions.
      </Typography>
      <form
        autoComplete='off'
      // onSubmit={() => { onSubmit(10) }}
      >
        <Grid
          sx={{ padding: '15px 0' }}
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            {/* <Grid flexDirection={'row'} spacing={2} display={'flex'} container flexWrap={'nowrap'}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <TextField
                  disabled
                  type='tel'
                  label='Mobile'
                  inputProps={{
                    type: 'tel',
                  }}
                  value={`+${customer.country_code}${customer.mobile}`}
                  fullWidth
                />
              </Grid>
            </Grid> */}
            <Grid flexDirection={'row'} spacing={2} display={'flex'} container flexWrap={'nowrap'}>
              <Grid item xs={5} sm={4}>
                <AppCountrySelect
                  countryPhone={customer.country_code ?? ''}
                  onChange={() => { }}
                  disabled />
              </Grid>
              <Grid item xs={7} sm={8}>
                <TextField
                  type='tel'
                  label="Mobile"
                  inputProps={{
                    type: 'tel',
                  }}
                  value={customer.mobile}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          {disabledFields.gender && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <FormLabel id='demo-row-radio-buttons-group-label' sx={{ textAlign: 'left' }}>
                Title (optional)
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={gender}
                onChange={(event) => {
                  setGender(event.target.value)
                }}
              >
                <FormControlLabel value='M' control={<Radio />} label='Mr.' />
                <FormControlLabel value='F' control={<Radio />} label='Mrs.' />
                <FormControlLabel value='O' control={<Radio />} label='Other' />
              </RadioGroup>
            </FormControl>
          </Grid>}

          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <TextField
                key='fullName'
                name='fullName'
                label='Name'
                fullWidth
                autoComplete='none'
                value={name}
                onChange={(event) => {
                  setName(event.target.value)
                }}
              />
            </FormControl>
          </Grid>

          {disabledFields.dob && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Datepicker
              open={open}
              handleClose={() => { setOpen(false) }}
              handleOpen={() => { setOpen(true) }}
              value={dob}
              label='Date of Birth'
              onChange={setDob} />

          </Grid>}

          {disabledFields.email && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <TextField
                key='email'
                name='email'
                label='Email (optional)'
                fullWidth
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                autoComplete='none'
                type='email'
              />
            </FormControl>
          </Grid>}
          {disabledFields.acqiusition && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <InputLabel id='demo-simple-select-label'>How did you first hear about us? (optional)</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={acqiusition}
                label='Acquisition Channel'
                onChange={(event) => {
                  setAcqiusition(Number(event.target.value))
                }}
                fullWidth
              >
                <MenuItem value={1}>Google search</MenuItem>
                <MenuItem value={2}>Social media</MenuItem>
                <MenuItem value={3}>Walk-in</MenuItem>
                <MenuItem value={4}>Friends</MenuItem>
                <MenuItem value={5}>Online advertisements</MenuItem>
                <MenuItem value={6}>Offline advertisements</MenuItem>
              </Select>
            </FormControl>
          </Grid>}
        </Grid>
      </form>
    </>
  )

  const SurveyRateForm = (
    <>
      {surveyDone ? (
        <Typography
          variant='body1'
          sx={{ mt: 2, mb: 1, color: 'text.secondary' }}>
          Thank you for your time.
        </Typography>
      ) : (
        <Box>
          <Collapse in={rate < 0} timeout='auto' unmountOnExit>
            <Typography
              variant='body1'
              sx={{ color: 'text.secondary' }}>
              From 0 to 10, how likely are you to recommend us to a friend or colleague?
            </Typography>
            {/* <Rate value={rate} setter={setRate} lastSaleId={lastSaleId} /> */}
            <Rate value={rate} onChange={handleRateChange} />
            {/* <Divider variant="middle" /> */}
          </Collapse>

          <Collapse in={rate >= 0} timeout='auto'>
            {rate < 7 && (
              <Box>
                <Typography
                  variant='body1'
                  sx={{ color: 'text.secondary' }}>
                  What are the main reasons for your satisfaction or dissatisfaction?
                </Typography>
                <TextField
                  multiline
                  rows={6}
                  placeholder='Write here...'
                  sx={{ padding: '15px 0', width: '100%' }}
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value)
                  }}
                />
                <CardActions sx={{ px: 0 }}>
                  <Button
                    sx={{ width: '100%' }}
                    size='large'
                    variant='contained'
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </CardActions>
              </Box>
            )}
            {rate >= 7 && (
              <Box>
                <Typography
                  variant='body1'
                  sx={{ mt: 2, mb: 1, color: 'text.secondary' }}>
                  Thank you for your time.
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ color: 'text.secondary' }}>
                  Please leave us a review on Google and tell your most satisfaction reasons.
                </Typography>
                <CardActions sx={{ marginTop: '16px' }}>
                  <LoadingButton
                    loading={loadingButton}
                    sx={{ width: '100%' }}
                    size='large'
                    variant='contained'
                    onClick={() => window.open(business?.google_link, '_self')}
                  >
                    Open Google
                  </LoadingButton>
                </CardActions>
              </Box>
            )}
          </Collapse>
        </Box>
      )}
    </>
  )
  const steps = [
    {
      label: 'Profile',
      component: SurveyProfileForm,
    },

    {
      label: 'Survey',
      component: SurveyRateForm,
    },
  ]

  return (
    <>
      {customer.last_sale_id === null ? (
        <>
          <Container>
            <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
              <Typography variant='h3' paragraph>
                You completed survey before!
              </Typography>

              <Typography
                variant='body1'
                sx={{ color: 'text.secondary' }}>
                Thank you for your time.
              </Typography>

              <Box
                component='img'
                src='/assets/illustrations/illustration_done.svg'
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </StyledContent>
          </Container>
        </>
      ) : (
        <StyledContent>
          <Avatar src={business.icon} alt='photoURL' sx={{ width: 96, height: 96 }} />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
              {business.name}
            </Typography>
          </Box>

          <StyledCard>
            <CardContent>
              <Stepper activeStep={activeStep} >
                {steps.map((step, index) => {
                  return (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          isStepOptional(index) ? (
                            <Typography variant='caption'>Optional</Typography>
                          ) : (
                            <></>
                          )
                        }
                      >
                        {step.label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              <Box sx={{ marginTop: '16px' }}>
                {steps[activeStep].component}
                {activeStep >= 0 && activeStep < steps.length - 1 ? (
                  <StyledCardActions sx={{ px: 0 }}>
                    <LoadingButton
                      loading={nextLoadingButton}
                      sx={{ width: matches ? 'fit-content' : '100%' }}
                      onClick={nextHandler}
                      variant='contained'
                      size='large'
                    >
                      Next
                    </LoadingButton>
                    {activeStep > 0 && (
                      <LoadingButton
                        // loading={backLoadingButton}
                        sx={{ width: matches ? 'fit-content' : '100%', justifySelf: 'flex-start' }}
                        // onClick={() => {

                        // }}
                        variant='outlined'
                        size='large'
                        color='secondary'
                      >
                        back
                      </LoadingButton>
                    )}

                  </StyledCardActions>
                ) : (
                  <></>
                )}
              </Box>
            </CardContent>
          </StyledCard>
          <Box sx={{ textAlign: 'center', marginTop: 'auto', marginBottom: '50px' }}>
            <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
              Powered by EngagePackage.com
            </Typography>
          </Box>
        </StyledContent>
      )}
    </>
  )
}
export default SurveyBusinessStepper
