import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { SnackbarProvider } from 'notistack'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from 'store'
// routes
import router from './routes'
// theme
import ThemeProvider from './theme/index'
// components
import Notifier from 'components/notifier'
// import { StyledChart } from './components/chart';

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <HelmetProvider>
          <SnackbarProvider>
            <ThemeProvider>
              <Notifier />
              <RouterProvider router={router} />
            </ThemeProvider>
          </SnackbarProvider>
        </HelmetProvider>
      </LocalizationProvider>
    </Provider>
  )
}

export default App
