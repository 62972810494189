// @mui
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import type { Breakpoint } from '@mui/material/styles'
// ----------------------------------------------------------------------

export default function useResponsive(
  query: 'up' | 'down' | 'between',
  start: Breakpoint,
  end?: Breakpoint,
): boolean {
  const theme = useTheme()

  const mediaUp = useMediaQuery(theme.breakpoints.up(start))

  const mediaDown = useMediaQuery(theme.breakpoints.down(start))

  const mediaBetween =
    end !== undefined
      ? useMediaQuery(theme.breakpoints.between(start, end))
      : useMediaQuery(theme.breakpoints.up(start))

  const mediaOnly = useMediaQuery(theme.breakpoints.only(start))

  if (query === 'up') {
    return mediaUp
  }

  if (query === 'down') {
    return mediaDown
  }

  if (query === 'between') {
    return mediaBetween
  }

  return mediaOnly
}

// ----------------------------------------------------------------------
