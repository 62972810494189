import React, { useState } from 'react'
// @mui
import { TextField, Grid, Box, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import axios from 'axios'
import { AppCountrySelect } from 'sections/@dashboard/app'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
// ----------------------------------------------------------------------

const CustomerLoginForm = (): JSX.Element => {
  const [countryPhone, setCountryPhone] = useState('')
  const [mobile, setMobile] = useState('')
  const navigate = useNavigate()


  const loginHandler = (): void => {
    console.log({ mobile, countryPhone })

    axios
      .post(`customer/login/`,
        { mobile, country_code: countryPhone }
      )
      .then((res) => {
        navigate('/LoginSuccessfulPage')

      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.detail, { variant: 'error' })
        console.log(err)
      })
  }
  return (
    <>
      <Box>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Loyalty Profile Login
            </Typography>
          </Grid>
  
          <Grid item xs={6} sm={4}>
            <AppCountrySelect
              countryPhone={countryPhone}
              onChange={setCountryPhone}
            />
          </Grid>
          <Grid item xs={6} sm={8} >
            <TextField
              type='tel'
              label='Mobile'
              inputProps={{
                type: 'tel',
              }}
              value={mobile}
              fullWidth
              onChange={(event) => {
                setMobile(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>

            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              onClick={loginHandler}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>


    </>
  )
}
export default CustomerLoginForm
