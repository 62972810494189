import React, { useState } from 'react'

import {
  Avatar,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { PointType, type Reward } from 'types/customer'
import requestInstance from 'utils/request'
import { useCustomerAuth } from 'hooks/useCustomerAuth'
import { LoadingButton } from '@mui/lab'
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1, 1),
  //   textAlign: 'center',
  alignItems: 'center',
}))

const StyledCard = styled(Card)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  width: '100%',
  //   height:'100%',
  //   justifyContent: 'space-between',
  flex: 1,
}))

const RewardDetailPage = (): JSX.Element => {
  const navigate = useNavigate()
  const reward = useLoaderData() as Reward
  const { user } = useCustomerAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const handleRedeem = (): void => {
    setLoading(true)
    requestInstance.post(`/redeem/`, {
      customer: user?.id,
      reward: reward.id
    }).then(res => {
      if (res.status === 201) {
        setLoading(false)
        navigate(`/redeemSuccessfulPage/${res.data.id}`)
      }
    }
    ).catch(err => {
      console.log(err)
      setLoading(false)
    })

  }
  return (
    <>
      <StyledContent>
        <Avatar
          src={reward.business.icon}
          alt='photoURL'
          sx={{ width: 96, height: 96 }}
        />
        <Typography variant='subtitle2' sx={{ marginBottom: '10px' }}>
          {reward.business.name}
        </Typography>
        <StyledCard>
          <CardMedia
            component='img'
            src={reward.cover_photo}
            // src='https://placehold.co/400x400'
            // image='/assets/images/covers/cover_9.jpg'
            alt='Live from space album cover'
          />
          <CardContent sx={{ overflowY: 'auto' }}>
            <Box>

              <Typography variant='h5'>{reward.name}</Typography>
              <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                {reward.loyalty_weight} {reward.business.loyalty_model !== undefined ? PointType[reward.business.loyalty_model] : ''}
              </Typography>
            </Box>
            <Typography variant='body1'>
              {reward.description}
            </Typography>

          </CardContent>
          <CardActions sx={{ marginTop: 'auto' }}>
            <LoadingButton
              sx={{ flex: 1 }}

              loading={loading}
              disabled={!reward.useable}
              size='large'
              variant='contained'
              // sx={{ peosition: 'sticky', bottom: 0, borderRadius: 0 }}
              onClick={handleRedeem}
            >
              Redeem
            </LoadingButton>
            <Button
              sx={{ flex: 1 }}
              size='large'
              variant='outlined'
              onClick={() => { navigate('/profile') }}
            >
              Back
            </Button>
          </CardActions>
        </StyledCard>
        <Box sx={{ marginTop: 'auto' }}>
          <Typography variant='body2' sx={{ color: 'text.primary', margin: '20px' }}>
            Powered by EngagePackage.com
          </Typography>
        </Box>
      </StyledContent>
      <Box>


      </Box>
    </>
  )
}
export default RewardDetailPage
