import React from 'react'
import { createBrowserRouter, defer, redirect } from 'react-router-dom'
import requestInstance from './utils/request'
import OneTimePassword from 'components/oneTimePassword/OneTimePassword'

// layouts
import SimpleLayout from './layouts/simple'
import ProtectedLayout from './layouts/protected/ProtectedLayout'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import CustomerAuthLayout from 'layouts/CustomerAuthLayout/CustomerAuthLayout'
//  pages
import LoginPage from './pages/LoginPage'
import DashboardAppPage from './pages/DashboardAppPage'
import CustomerPage from 'pages/CustomerPage'
import SurveyPage from 'pages/SurveyPage'
import CustomerProfile from 'pages/CustomerProfile'
import RewardDetailPage from 'pages/RewardDetailPage'
import NotFoundPage from 'pages/NotFoundPage'
import CustomerLoginPage from 'pages/CustomerLoginPage'
import LoginSuccessfulPage from 'pages/LoginSuccessfulPage'
//  types
import type { CustomerUser, user } from './types/auth'
import type { Customer, CustomerListAPI, Business, RewardListAPI } from 'types/customer'
import { type LoaderFunctionArgs } from 'react-router-dom'

import Redirect from 'components/redirect'
import QrCodePage from 'pages/QrCodePage'
import RedeemSuccessfulPage from 'pages/RedeemSuccessfulPage'
const getUserData = async (): Promise<string | null> => {
  return await new Promise((resolve, reject) => {
    const user = window.localStorage.getItem('user')

    user !== null && resolve(user)
    reject(new Error('user not found'))
  })
}
const getCustomerData = async (): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const user = window.localStorage.getItem('customerUser')
    user !== null && resolve(user)
    reject(new Error('user not found'))
  })
}
const routes = [
  {
    path: '/',

    element: <SimpleLayout />,
    children: [
      {
        path: '/',
        element: <Redirect url={`/dashboard`} />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/customerlogin',
        element: <CustomerLoginPage />,
      },

      {
        path: '/survey',
        // element: <SurveyPage />,
        // loader: async ({ request }: { request: Request }) => {
        //   const url = new URL(request.url)
        //   const customerId = url.searchParams.get('customer')
        //   const businessId = url.searchParams.get('business')
        //   const user = window.localStorage.getItem('surveyCustomer')
        //   const businessRes = await requestInstance.get<Business>(
        //     encodeURI(`business/${businessId}`),
        //   )
        //   if (
        //     businessId !== null && customerId === 'anonymous'
        //   ) {
        //     if (user) {
        //       const customerRes = await requestInstance.get<Customer>(
        //         encodeURI(`customer/${JSON.parse(user).id}`),
        //       )
        //       return { type: 'customer', business: businessRes.data, customer: customerRes.data }
        //     }
        //     return { type: 'anonymous', business: businessRes.data, customer: null }

        //   }

        //   if (businessId !== null && customerId === 'unknown') {
        //     if (user) {
        //       const customerRes = await requestInstance.get<Customer>(
        //         encodeURI(`customer/${JSON.parse(user).id}`),
        //       )
        //       return { type: 'customer', business: businessRes.data, customer: customerRes.data }
        //     }
        //     return { type: 'customer', business: businessRes.data, customer: null }
        //   }
        //   if (businessId !== null && customerId !== null) {
        //     const customerRes = await requestInstance.get<Customer>(
        //       encodeURI(`customer/${customerId}`),
        //     )
        //     return { type: 'business', business: businessRes.data, customer: customerRes.data }
        //   }

        //   return redirect("/404");
        // },
        children: [


          {
            path: '/survey',
            element: <SurveyPage />,
            loader: async ({ request }: { request: Request }) => {
              const url = new URL(request.url)
              const customerId = url.searchParams.get('customer')
              const businessId = url.searchParams.get('business')
              const approval = url.searchParams.get('approval')
              const user = window.localStorage.getItem('surveyCustomer')
              const businessRes = await requestInstance.get<Business>(
                encodeURI(`business/${businessId}`),
              )
              if (
                businessId !== null && customerId === 'anonymous'
              ) {
                if (user !== null) {
                  const customerRes = await requestInstance.get<Customer>(
                    encodeURI(`customer/${JSON.parse(user).id}`),
                  )
                  return { type: 'customer', business: businessRes.data, customer: customerRes.data, approval }
                }
                return { type: 'anonymous', business: businessRes.data, customer: null, approval: '' }

              }
              if (businessId !== null && customerId === 'unknown') {
                if (user !== null) {
                  const customerRes = await requestInstance.get<Customer>(
                    encodeURI(`customer/${JSON.parse(user).id}`),
                  )
                  return { type: 'customer', business: businessRes.data, customer: customerRes.data, approval }
                }
                return { type: 'customer', business: businessRes.data, customer: null, approval }
              }
              if (businessId !== null && customerId === 'empty') {

                return { type: 'empty', business: businessRes.data, customer: null, approval }
              }
              if (businessId !== null && customerId !== null) {
                const customerRes = await requestInstance.get<Customer>(
                  encodeURI(`customer/${customerId}`),
                )
                localStorage.setItem('surveyCustomer', JSON.stringify({ id: customerRes.data.id }))

                return { type: 'business', business: businessRes.data, customer: customerRes.data, approval: '' }
              }

              return redirect("/404");
            },
          },
          {
            path: 'bussiness/:bussinessId',
            loader: async ({ params }: LoaderFunctionArgs) => {
              return redirect(`/survey?business=${params.bussinessId}&customer=unknown`)
            }
          },
          {
            path: 'anonymous/:bussinessId',
            loader: async ({ params }: LoaderFunctionArgs) => {
              return redirect(`/survey?business=${params.bussinessId}&customer=anonymous`)
            }
          },
        ]
      },

      {
        path: '/LoginSuccessfulPage',
        element: <LoginSuccessfulPage />,
      },
      {
        path: '/redeemSuccessfulPage/:id',
        element: <RedeemSuccessfulPage />,
      },


      {
        element: <OneTimePassword />,
        path: '/userverification',
        loader: async ({ request }: { request: Request }) => {
          const url = new URL(request.url)
          const verificationCode = url.searchParams.get('code')
          const redirectUrl = url.searchParams.get('redirect')
          try {
            // const localUser = await getCustomerData()
            const localUser = window.localStorage.getItem('customerUser')

            console.log('localUser router')
            console.log(localUser)
            // if (localUser !== null) { return redirect(redirectUrl ?? '/profile') }
            const customerRes = await requestInstance.post<CustomerUser>(`/customer/verify/`, {
              validation_code: verificationCode,
            })
            const customer = customerRes.data
            return { customer, redirectUrl }

          } catch (err) {
            console.log(err)
            return redirect('/404')
          }
        },
      },


    ],
  },

  {
    element: <AuthLayout />,
    loader: () => defer({ userPromise: getUserData() }),
    children: [
      {
        element: <ProtectedLayout />,
        children: [

          {
            element: <DashboardAppPage />,
            path: '/dashboard',
            loader: async () => {
              //  BUUUUUUUUUUUUUUUUUUUGGGG
              const user: user | null =
                localStorage.getItem('user') !== null
                  ? JSON.parse(localStorage.getItem('user') ?? `{"id":"null"}`)
                  : null
              if (user !== null) {
                const res = await requestInstance.get(`business/${user?.id?.toLowerCase()}`)
                return res.data
              }
              return null
            },
          },
          {
            element: <CustomerPage />,
            path: '/customer',
            loader: async ({ request }: { request: Request }) => {
              const url = new URL(request.url)
              const mobile = url.searchParams.get('mobile')
              const countryCode = url.searchParams.get('country_code')
              const businessId = url.searchParams.get('business')
              const res = await requestInstance.get<CustomerListAPI>(
                encodeURI(`customer?${url.searchParams.toString()}`),
              )
              if (res.data.data.length > 0) {
                return res.data.data[0]
              }
              const customer: Customer = {
                gender: '',
                full_name: '',
                email: '',
                dob: '',
                mobile: mobile ?? undefined,
                country_code: countryCode ?? '',
                business: { id: businessId ?? undefined },
              }
              return customer
            },
          },
          {
            path: '/qrcode',
            element: <QrCodePage />,
          },

        ],
      },

    ],
  },
  {
    element: <CustomerAuthLayout />,
    loader: () => defer({ userPromise: getCustomerData() }),
    children: [

      {
        path: '/profile',
        element: <CustomerProfile />,

      },
      {
        path: '/rewarddetail/:rewardId',
        element: <RewardDetailPage />,
        loader: async ({ params }: LoaderFunctionArgs) => {
          try {
            const user = JSON.parse(window.localStorage.getItem('customerUser') ?? '')
            const rewardRes = await requestInstance.get<RewardListAPI>(`/reward/?id=${params.rewardId}&customer=${user.id}`)
            if (rewardRes.data.data.length > 0) {
              return rewardRes.data.data[0]
            } else {
              throw new Error('requested reward not found')
            }
          }
          catch (err) {
            return err
          }

        }

      },


    ]
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
const router = createBrowserRouter(routes)
export default router
