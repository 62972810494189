import React, { useEffect, useState } from "react";
import { QRCodeSVG } from 'qrcode.react';
import { Helmet } from "react-helmet-async";
import { Typography, Stack, Card, Box, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import requestInstance from "utils/request";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "hooks/useAuth";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: `calc(100vh - 168px)`,

}));


const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    width: 'fit-content',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
}))

// ----------------------------------------------------------------------


const QrCodePage = (): JSX.Element => {
    const { user } = useAuth()
    const MINUTE_MS = 5000;
    const [url, setUrl] = useState<string>('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            requestInstance.head(`business/${user?.id}/code/`).then(res => {
                setLoading(false)
                setUrl(res.headers['x-link'])
            }).catch(err => {
                enqueueSnackbar(err.response.data.message, { variant: 'error' })
            })
        }, MINUTE_MS);

        return () => { clearInterval(interval) }; // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    return (
        <>
            <Helmet>
                <title> All Done | EngagePackage </title>
            </Helmet>

            <Box>
                <StyledContent >
                    <StyledCard>
                        <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h3" paragraph>
                                Scan Me!
                            </Typography>
                            {loading ? <CircularProgress /> : <QRCodeSVG value={url} />}

                            <Typography sx={{ color: 'text.secondary' }}>Thank you for your time.</Typography>
                        </Stack>

                    </StyledCard>
                </StyledContent>
            </Box>

        </>
    )
}
export default QrCodePage